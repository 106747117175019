<template>
  <fragment>
    <v-col
      cols="12"
      md="6"
    >
      <v-card>
        <v-card-text>
          <v-row class="mt-2">
            <v-col
              cols="12"
              sm="11"
              md="11"
            >
              <v-text-field
                v-model="url.url"
                label="URL"
                placeholder="https://youtu.be"
                outlined
                dense
                hide-details
                :disabled="$store.state.app.onlyShow"
              >
                <v-tooltip
                  slot="append-outer"
                  top
                  color="error"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="pt-0 mx-2"
                      fab
                      dark
                      x-small
                      color="error"
                      @click="deleteUrlVideo(pos)"
                    >
                      <v-icon
                        v-bind="attrs"
                        small
                        v-on="on"
                      >
                        {{ icons.mdiTrashCan }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('btn.delete') }}</span>
                </v-tooltip>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <video-embed
                :src="url.url"
                css="embed-responsive-1by1"
              ></video-embed>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </fragment>
</template>

<script>
import { mapMutations } from 'vuex'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiTrashCan,
  mdiAirplaneTakeoff,
  mdiCity,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    url: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
  },
  data() {
    return {
      isLoading: true,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiTrashCan,
        mdiAirplaneTakeoff,
        mdiCity,
      },
      loading: false,
    }
  },

  created() {
    this.isLoading = true
  },
  methods: {
    ...mapMutations(['deleteUrlVideo', 'adicionarEmailContactHotel', 'adicionarTelefonoContactHotel']),
  },
}
</script>
<style>
iframe {
  border: 0;
  width: 95% !important;
  height: 250px !important;
}
</style>
